<template>
    <div
        style="
            /* position: relative; */
        "
    >
        <Button @click="copyToClipBoard()">
            Copy Table
        </Button>

        <table
            style="
                position: absolute;
                top: -9999px;
                left: -9999px;
            "
            :id="'CopyTableCountryDataSimpliefId'"
        >
            <thead
                class="CopyTable-CountryData-exportTable-head"
            >
                <tr>
                    <th
                        v-for="(headerItem, headerItemIndex) in header"
                        :key="headerItemIndex"
                        style="
                        "
                        
                    >
                        {{ headerItem }}
                    </th>
                </tr>
            </thead>
            <tbody
                class="CopyTable-CountryData-exportTable-body"
            >
                <template
                    v-for="(region, regionIndex) in segmentationRegionOrder"
                    :key="'region-'+ regionIndex"
                >
                    <tr>
                        <td
                            v-for="(headerItem, headerItemIndex) in headerTotalData[regionIndex]"
                            :key="headerItemIndex"
                            class="CopyTable-CountryData-exportTable-body-total"
                        >
                            <div v-if="headerItemIndex === 0 " class="CopyTable-CountryData-exportTable-body-rowDesc">
                                {{ headerItem  }}
                            </div>
                            <div v-else>
                            </div>
                        </td>
                    </tr>

                    <tr
                        v-for="(row, rowIndex) in countryData[regionIndex]"
                        :key="'row-'+regionIndex+'-'+rowIndex"
                    >
                        <td
                            v-for="(item, itemIndex) in row" 
                            :key="itemIndex"
                            style="
                            "
                        >
                            <div v-if="itemIndex === 0 || itemIndex === 1 " class="CopyTable-CountryData-exportTable-body-rowDesc">
                                {{ item }}
                            </div>
                            <div v-else >
                                {{ item }}
                            </div>
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
        
    </div>
</template>

<script>
import Button from 'primevue/button';

export default{
    name: 'CopyTableCountryDataSimplified',
    emits:[],
    props:['header','segmentationRegionOrder','countryData','headerTotalData'],

    components:{
        Button
    },

    data(){
        return{

        };
    },

    mounted() {
        // console.log(this.headerTotalData);
    },


    methods: {
        copyToClipBoard(){
            var range = document.createRange();
            let containerNode = document.getElementById('CopyTableCountryDataSimpliefId'); 
            // containerNode.style.visibility = 'visible';
            // TODO UNCHECK
            containerNode.style.position = 'static'; // move content to the screen

            range.selectNode(containerNode); ///
            window.getSelection().removeAllRanges();
            window.getSelection().addRange(range);
            document.execCommand("copy");
            window.getSelection().removeAllRanges();
            // containerNode.style.visibility = 'hidden';
            // TODO UNCHECK
            containerNode.style.position = 'absolute'; // hide content off the screen
            containerNode.style.top= '-9999px';
            containerNode.style.left= '-9999px';
            alert("data copied");
        },
        formatNumber(data){
            if(data){
                // split int and decimals of number
                let [int, decimal] = data.toString().split('.');

                //format int with , 
                int = int.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                return decimal ? `${int}.${decimal}` : int
            }
            else{
                return data
            }
        },
    },
}
</script>

<style scoped>
/* styles class are on CopyTableSingleSegmentationSimplified */

.CopyTable-CountryData-exportTable-head th{
    background-color: #3f5f8f;
    border-collapse: collapse;
    border: 1px solid #cad8ef;
    padding: 2px;
    font-family: Arial;
    font-size: 12px;
    text-align: center;
    color: #FFFFFF;
    vertical-align: top;
}

.CopyTable-CountryData-exportTable-body td{
    border-collapse: collapse;
    border: 1px solid #cad8ef;
    padding: 2px;
    font-family: Arial;
    font-size: 11px;
    text-align: right;
}

.CopyTable-CountryData-exportTable-body .CopyTable-CountryData-exportTable-body-rowDesc {
    text-align: left;
}
.CopyTable-CountryData-exportTable-body .CopyTable-CountryData-exportTable-body-total{
    font-weight: bold;

}


</style>