<template>
    <div style="
        position: relative;
        /* top: 110%; */
        /* left: -0%; */
    ">
        <Button @click="copyToClipBoard()">
            Copy Table
        </Button>

        <table 
            style="
                position: absolute;
                top: -9999px;
                left: -9999px;
            "     
            :id="'CopyTableCountryDataId'"
        >
            <!-- header table -->
            <tr>
                <th
                    v-for="(header, index) in this.header"
                    :key="index"
                >
                    {{ header }}
                </th>
            </tr>
            <!-- data -->
            <tr
                v-for="(row, rowIndex) in this.countryData" 
                :key="rowIndex"
            >
                <td
                    v-for="(cell, cellIndex) in row"
                    :key="cellIndex"
                >
                    <!-- {{ formatNumber(cell) }} -->
                    {{ formatNumber(cell)  }}
                </td>
            </tr>

            <!-- total -->
            <tr>
                <td
                    v-for="(total, totalIndex) in this.totalData"
                    :key="totalIndex"
                >
                    {{ formatNumber(total)  }}

                </td>
            </tr>

        </table>
    </div>
</template>

<script>
import Button from 'primevue/button';

export default {
    name: 'CopyTableCountryData',
    emits:[],
    props:['header', 'countryData','totalData'],


    components: {
        Button
    },

    data(){
        return {
            
        };
    },
     methods: {
        copyToClipBoard(){
            var range = document.createRange();
            let containerNode = document.getElementById('CopyTableCountryDataId'); 
            // containerNode.style.visibility = 'visible';
            // TODO UNCHECK
            containerNode.style.position = 'static'; // move content to the screen

            range.selectNode(containerNode); ///
            window.getSelection().removeAllRanges();
            window.getSelection().addRange(range);
            document.execCommand("copy");
            window.getSelection().removeAllRanges();
            // containerNode.style.visibility = 'hidden';
            // TODO UNCHECK
            containerNode.style.position = 'absolute'; // hide content off the screen
            containerNode.style.top= '-9999px';
            containerNode.style.left= '-9999px';
            alert("data copied");
        },
        formatNumber(data){
            if(data){
                // split int and decimals of number
                let [int, decimal] = data.toString().split('.');

                // format int with , 
                int = int.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                return decimal ? `${int}.${decimal}` : int
            }
            else{
                return data
            }
        },
    },

}

</script>

<style scoped>
</style>
