<template>
    <!-- table -->
    <div 
        v-if="this.header.length > 0"

        style="
            margin-bottom: 5rem;
        "
    >
        <table style="
            width: 100%;
            height: 100%;
        ">
            <!-- header table -->
            <tr class="topicOutputTrTextAlign">
                <th 
                    v-for="(header, index) in this.header" 
                    :key="index"
                    class="topicOutputTh"
                >
                    {{ header }}
                </th>
            </tr>
            <!-- data -->
            <tr 
                v-for="(row, rowIndex) in this.countryData" 
                :key="rowIndex"
                
                class="topicOutputTrTextAlign"
            >
            <!-- :class="{'row-zero':isRowAllZero[rowIndex]}" -->
             <!-- {{ this.countryData[rowIndex] }} -->
                <td 
                    v-for="(cell, cellIndex) in row" 
                    :key="cellIndex" 
                    :class="{'row-zero':isRowAllZero[rowIndex]}"
                    class="topicOutputTh"
                >
                    {{ formatNumber(cell) }}
                </td>
            </tr>
            <!-- total -->
            <tr class="topicOutputTrTextAlign" >
                <td
                    v-for="(total, totalIndex) in this.totalData"
                    :key="totalIndex"
                    class="topicOutputTh"
                >
                    {{ formatNumber(total)  }}
                </td>
            </tr>
            
        </table>

        <div
            style="
                position: relative;
            "
        >
            <CopyTableCountryData 
                :header="this.header"
                :countryData="this.countryData"
                :totalData="this.totalData"
            />
        </div>
    </div>
</template>

<script>
import CopyTableCountryData from './CopyTableCountryData.vue';

export default {
    name: 'TableCountryData',
    emits: [],
    props: ['countriesData','valueDisplayOptionOutput','decimalsOptionOutput','firstYear', 'lastYear'],
    components: {
        CopyTableCountryData,
    },

    data() {
        return {
            loading: false,
            header:[],
            countryData:[],
            totalData: [],
        };
    },
    async mounted(){
        // Checklogs
        this.convertHeaderTable();
        this.convertDataTable();
        this.convertTotalRow();
    },
    computed:{
        isRowAllZero(){
            // used for validation
            let rowData = JSON.parse(JSON.stringify(this.countryData))
            rowData.forEach(cd => { cd.shift(); cd.shift();cd.shift();} );

            return rowData.map(r => r.every(cell => cell === 0 || cell === '0.0' || cell === '0.00' || cell === '0.000'));
        }

    },
    methods: {
        convertHeaderTable(){
            // NAME , ID 
            this.header = ['Country','Region','RegionOrder']

            // YEARS 
            this.countriesData[0].regionCountries[0].docProgramTopicSegItems[0].documentProgramTopic.documentProgramValues.forEach(documentProgramValue => {
                this.header.push(documentProgramValue.year)
            });

            // SIZE , CAGRcalc , CAGR

            let fy = this.firstYear.toString().slice(-2);
            let ly = this.lastYear.toString().slice(-2);
            

            this.header.push(`∑${fy}-${ly}size`);
            this.header.push(`CAGR ${fy}-${ly}`);

        },
        convertDataTable(){
            //TEST
            // let slicedData = this.countriesData.slice(0,5);
            // console.log('data',this.countriesData);
            let slicedData = this.countriesData;

            for (let index = 0; index < slicedData.length; index++) {
                const element = slicedData[index];
                // a goal [name, 'countryCode','years','size',cagrCalc, cagr,] 
                let a = [];
                a.push(element.name) //name

                // a.push(element.countryCode) // countryCode
                a.push(element.regionCountries[0].region.name)
                a.push(element.regionCountries[0].region.segmentationItems[0].order)

                element.regionCountries[0].docProgramTopicSegItems[0].documentProgramTopic.documentProgramValues.forEach( documentProgramValue => {
                    a.push(this.toFixValueOptionDecimate(documentProgramValue.value))
                })

                // size ToDo sum with the data we have 
                if(element.regionCountries[0].docProgramTopicSegItems[0].documentProgramTopic.sumAll != null){
                    a.push( this.toFixValueOptionDecimate(element.regionCountries[0].docProgramTopicSegItems[0].documentProgramTopic.sumAll)  ) // size
                }
                else{
                    a.push( this.toFixValueOptionDecimate(0)) // errorSize or null Size
                }
                
                // cagrCalc
                // CAGR
                let roundedCagr
                if(element.regionCountries[0].docProgramTopicSegItems[0].documentProgramTopic.cagr != null){
                    // a.push( element.regionCountries[0].docProgramTopicSegItems[0].documentProgramTopic.cagr.toFixed(2) ) 
                    roundedCagr = parseFloat(element.regionCountries[0].docProgramTopicSegItems[0].documentProgramTopic.cagr.toFixed(1)); 
                }
                else{
                    // a.push( 0 ) 
                    roundedCagr = 0 // check how want to see 
                }

                
                a.push( roundedCagr ) 

                // console.log(a);
                this.countryData.push(a)
            }
        },
        convertTotalRow(){
            let countryDataClone = JSON.parse(JSON.stringify(this.countryData)) ;
            let totals = new Array(countryDataClone[0].length).fill(0); //get totalIndex 

            // set and Sum values for Totals
            countryDataClone.forEach( row => {
                row.forEach((value, index) => {
                    if(index >= 2  && index < row.length -1 ){
                        { 
                            // start 
                            totals[index] += parseFloat(value) || 0
                        }
                    }
                });
            });

            let totalDecimalFixed = totals.map(num => this.toFixValueOptionDecimate(num));

            // set totaltitle [Total, ]
            totalDecimalFixed[0] = "Total"
            // empty index 1 [Total, '']
            totalDecimalFixed[1] = ""
            // empty index 2 [Total, '','']
            totalDecimalFixed[2] = ""


            const cagrValue = this.calculateCAGR(totalDecimalFixed)
            // totalDecimalFixed[totals.length - 2] = parseFloat(cagrValue.toFixed(2))
            totalDecimalFixed[totals.length - 1] = parseFloat(cagrValue.toFixed(1))

            this.totalData = totalDecimalFixed

            
            // // console.log(totals);
        },
        calculateCAGR(values){
            let val = [...values]
            // [0,0,region, year, sum ,cagr1 ]
            // remove the lengths that not years Values
            val.shift()
            val.shift()
            val.shift()
            val.pop()
            val.pop()
            
            let initialValue = val[0];
            let finalValue = val[val.length -1];
            let n = val.length -1 

            const cagr = Math.pow(finalValue / initialValue, 1/n) -1;
            return (cagr) ? cagr * 100 : 0
        },
        convertValueDisplayOption(data){
            if(this.valueDisplayOptionOutput === 'billions'){
                return data / 1000;
            }
            else{
                return data
            }
        },
        toFixDecimate(data){
            if(this.decimalsOptionOutput === '0.'){
                if(Number.isInteger(data)){
                    return data
                }
                else{
                    return Math.round(data);
                }
            }
            if(this.decimalsOptionOutput === '.0'){
                return this.roundTo(data, 1).toFixed(1);
            }
            if(this.decimalsOptionOutput === '.00'){
                return this.roundTo(data, 2).toFixed(2);
            }
            if(this.decimalsOptionOutput === '.000'){
                return this.roundTo(data, 3).toFixed(3);
            }
        },
        toFixValueOptionDecimate(data){
            const valueDisplay = this.convertValueDisplayOption(data);
            return this.toFixDecimate(valueDisplay)
        },
        roundTo(num, decimals){
            let factor = Math.pow(10, decimals);
            return Math.round(num * factor) / factor; 
        },
        formatNumber(data){
            if(data){
                // split int and decimals of number
                let [int, decimal] = data.toString().split('.');

                //format int with , 
                int = int.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                return decimal ? `${int}.${decimal}` : int
            }
            else{
                return data
            }
        },

        cleanTable(){
            this.header = [];
            this.countryData= [];
            this.totalData = [];
        }
    },
    watch:{
        'countriesData':{
            async handler(newVal){
                if(newVal){
                    // clean Table
                    this.cleanTable();

                    // call mounted Functions 
                    this.convertHeaderTable();
                    this.convertDataTable();
                    this.convertTotalRow();
                }
            }
        }
    },
};
</script>

<style scoped>
.topicOutputTrTextAlign>*:not(:first-child) {
    text-align: right;
}

.topicOutputTh {
    border: solid gray 1px;

    /* padding: 0 1.5% 0 1.5%; */
    padding: 0 0.3% 0 0.3%;

}

.row-zero{
    background-color:#ff7b7b ;
    color: #FFFFFF;

}
</style>