<template>
    <div v-if="!loading">
        <div class="container">
            <!-- Header -->
            <div class="row">
                <div class="col-12 py-4">
                    <div class="p-fluid container-fluid">
                        <Button 
                            class="p-button-raised p-button-rounded my-3 p-button-danger" 
                            icon="pi pi-arrow-left"
                            @click="goBack"
                        />
                        <h3><strong>{{ topic.name }}</strong> Country Data</h3>
                    </div>
                </div>
            </div>

            <Divider align="left">
                <div class="inline-flex align-items-center">
                    <span class="p-tag">Country Data settings</span>
                </div>
            </Divider>

            <!-- Inputs fields -->
            <div
                style="
                    margin: 2rem;
                    display: grid;
                    grid-template-columns: 50%;
                    grid-template-rows: 1fr;
                    gap: 2.5%;
                "
            >
                <!-- FORM 1 -->
                <div>
                    <div class="export-flexColumn">
                        <label >Select Years(s)</label>
                        <div class="export-flexColumn-contractYear">
                            <Calendar 
                                placeholder="Select First Year"
                                v-model="firstYear"
                                view="year"
                                dateFormat="yy"
                                :showIcon="true"
                                class="mb-3"
                                style="width: 50%;"
                            />
                            <Calendar 
                                placeholder="Select Last Year"
                                v-model="lastYear"
                                view="year"
                                dateFormat="yy"
                                :showIcon="true"
                                class="mb-3"
                                style="width: 50%;"
                            />
                        </div>
                    </div>   
                    <div class="export-flexColumn">
                        <label> Scenario </label>
                        <Dropdown 
                            v-model="scenario" 
                            :options="topicScenarios" 
                            optionLabel="name"
                            optionValue="scenarioId" 
                            :showClear="true" 
                            placeholder="Scenario" 
                            class="mb-3"
                        />
                    </div>

                    <div class="export-flexColumn">
                        <div class="export-flexColumn-valueOptionDecimal">
                            <div class="export-flexColumn-valueOptionDecimal-container">
                                <label> Value display option</label>
                                <!-- <Dropdown style=" width: 100%"></Dropdown> -->
                                <Dropdown 
                                    v-model="valueDisplayOption" 
                                    :options="valueDisplayOptions" 
                                    placeholder="Select Value" 
                                    class="mb-3"
                                    style="width: 100%;"
                                />
                            </div>
                            <div class="export-flexColumn-valueOptionDecimal-container">
                                <label> Decimals</label>
                                <Dropdown 
                                    v-model="decimalsOption" 
                                    :options="decimalsOptions" 
                                    placeholder="Select Decimals" 
                                    class="mb-3"
                                    style="width: 100%;"
                                />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <!-- Button container  -->
            <div  
                style="
                    margin: 2rem 2rem 2rem 2rem;
                    margin-top: 5rem; 
                    display: flex; 
                    gap: 1rem;"
            >   
                <Button 
                    label="Preview tables and Charts" 
                    class=" ml-3"
                    @click="getPreview" 
                    :disabled="this.v$.firstYear.$invalid || this.v$.lastYear.$invalid || this.v$.scenario.$invalid "
                    :loading="loadingPreview" 
                >
                    <label style="margin-right: 0.5rem;"> Preview Country Data Chart</label>
                    <font-awesome-icon icon="fa-regular fa-floppy-disk" />
                </Button>
            </div>


        </div>

        <div class="container">
            <h1>PREVIEW - IN DEVELOPMENT</h1>
        </div>

        <div v-if="!loadingPreview">
            <div 
                class="container" 
                v-if="regionCountriesData.length > 0"
                style="
                    margin-bottom: 1rem; 
                    margin-top: 2rem;
                "
            >
                <TableCountryData 
                    :countriesData="regionCountriesData"
                    :valueDisplayOptionOutput="valueDisplayOptionOutput"
                    :decimalsOptionOutput="decimalsOptionOutput"
                    :firstYear="firstYearOutput" 
                    :lastYear="lastYearOutput" 
                />
                <!-- {{ regionCountriesData }} -->

                <TableCountryDataSimplified
                    :topicSegmentations="topicSegmentations"
                    :countriesData="validateCountriesWithOutValues(regionCountriesData)"
                    :valueDisplayOptionOutput="valueDisplayOptionOutput"
                    :decimalsOptionOutput="decimalsOptionOutput"
                    :firstYear="firstYearOutput"
                    :lastYear="lastYearOutput"
                />

                <br/>

                <TableCountryDataThird
                    :topicSegmentations="topicSegmentations"
                    :countriesData="validateCountriesWithOutValues(regionCountriesData)"
                    :valueDisplayOptionOutput="valueDisplayOptionOutput"
                    :decimalsOptionOutput="decimalsOptionOutput"
                    :firstYear="firstYearOutput"
                    :lastYear="lastYearOutput"
                />
            </div>
        </div>
        <div v-else>
            <div class="row">
                <div class="col-12 py-4 d-flex justify-content-center">
                    <ProgressSpinner />
                </div>
            </div>

        </div>
    </div>
    <!-- Loading -->
    <div v-else>
        <div class="row">
            <div class="col-12 py-4 d-flex justify-content-center">
                <ProgressSpinner />
            </div>
        </div>
    </div>

</template>

<script>
import Button from 'primevue/button';
import Calendar from 'primevue/calendar';
import Dropdown from 'primevue/dropdown';
import ProgressSpinner from 'primevue/progressspinner';
import Divider from 'primevue/divider';
import useVuelidate from '@vuelidate/core';

// 
import TableCountryData from '../components/CountryDataOutputComponents/TableCountryData.vue';
import TableCountryDataSimplified from '../components/CountryDataOutputComponents/TableCountryDataSimplified.vue';
import TableCountryDataThird from '../components/CountryDataOutputComponents/TableCountryDataThird.vue';

import { required } from '@vuelidate/validators';
import { useToast } from 'vue-toastification';

const toast = useToast();
export default {
    name:'CountryDataOutput',
    components:{
        Button,
        Calendar,
        Dropdown,
        ProgressSpinner,
        Divider,
        TableCountryData,
        TableCountryDataSimplified,
        TableCountryDataThird,
    },
    setup : () => ({v$: useVuelidate()}),
    data(){
        return{
            loading: true,

            firstYear: null,
            firstYearOutput:null,

            lastYear: null,
            lastYearOutput:null,

            scenario: null, 

            // loading preview
            loadingPreview:false,

            // output loading
            outputLoading: false,

            // output Error
            outputError: false,
            outputErrorMessage: '',

            //Value Display Options
            valueDisplayOptions: [
                'millions',
                'billions'
            ],
            valueDisplayOption:'millions',
            valueDisplayOptionOutput: null, //used for set ValueDisplayOption and not refresh tableComponent

            //Decimals
            decimalsOptions:[
                '0.','.0','.00','.000'
            ],
            decimalsOption: '0.',
            decimalsOptionOutput: null,
        }
    },
    validations(){
        return{
            firstYear: { required },
            lastYear: { required },
            scenario: { required },
        }
    },
    async mounted() {
        this.getYears();
        await this.getTopicById(this.$route.params.topicId)
        await this.getSegmentations(this.$route.params.topicId);

        //set Base Scenario
        let baseScenario = this.topicScenarios.find(topicScenario =>{
            return topicScenario.name === "Base Scenario" 
        })
        
        if(baseScenario){
            this.scenario = baseScenario.scenarioId
        }
        else{
            this.scenario = this.topicScenarios[0].scenarioId
        }
    },
    computed: {
        topic(){
            return this.$store.getters.obtainTopic;
        },
        topicScenarios(){
            const topic = this.$store.getters.obtainTopic;
            return topic.scenarios.sort((a,b)=> new Date(a.dateCreated) - new Date(b.dateCreated));
        },
        regionCountriesData(){
            const regionCountriesdata = this.$store.getters.obtainRegionCountriesData;
            let sortedByAlphabeticalRegionCountriesdata = regionCountriesdata.sort((a,b)=>{
                if(a.name < b.name){
                    return -1;
                }
                if(a.name > b.name){
                    return 1 
                }
                return 0
            })
            .sort((a,b) => {
                const orderA =  a.regionCountries[0].region.segmentationItems[0].order;
                const orderB = b.regionCountries[0].region.segmentationItems[0].order;
                return orderA - orderB;
            });
            return sortedByAlphabeticalRegionCountriesdata
        },
        topicSegmentations(){
            const segmentations = this.$store.getters.obtainSegmentations;
            return segmentations.filter(item=> item.isRegional)[0]
        },
    },
    methods: {
        goBack(){
            this.$router.go(-1);
        },
        getYears(){
            // SET YEARS FOR OUTPUT FORM
            const date = new Date(); 
            this.firstYear = date; 

            const year = date.getFullYear();
            const month = date.getMonth();
            const day = date.getDate(); 
            const dateLastYear = new Date(year + 8, month, day);
            this.lastYear = dateLastYear
        },
        async getTopicById(topicId){
            try {
                this.loading = true; 
                await this.$store.dispatch('obtainTopic', topicId);
                this.loading = false; 
            } catch (error) {
                toast.error(error.message);
                this.loading = false;
            }
        }, 

        async getSegmentations(topicId){
            try {
                this.loading = true;
                await this.$store.dispatch('obtainSegmentations', topicId);
                this.loading = false;
            } catch (error) {
                toast.error(error.message)
                this.loading = false;
            }
        },

        async getPreview(){
            this.loadingPreview = true; 
            try {
                const startYear = new Date(this.firstYear).getFullYear();
                const endYear = new Date(this.lastYear).getFullYear();

                let form = {
                    scenarioId: this.scenario,
                    startYear: startYear,
                    endYear: endYear,
                }

                await this.getCountryData(this.$route.params.topicId, form)

                // SET VALUEDISPLAYOPTIONOUTPUT
                this.valueDisplayOptionOutput = this.valueDisplayOption
                // SET DECIMALSOPTIONOUTPUT
                this.decimalsOptionOutput = this.decimalsOption

                // SET FIRST LAST YEARS OUTPUT
                this.firstYearOutput = startYear;
                this.lastYearOutput = endYear;

                this.loadingPreview = false
            } catch (error) {
                console.log(error);
                this.loadingPreview = false;
            }
        },

        async getCountryData(topicId, form){
            try {
                this.outputLoading = true; 
                await this.$store.dispatch('obtainRegionCountriesData',{topicId: topicId, form: form})
                this.outputLoading = false;
                this.outputError = false; 
                this.outputErrorMessage = null;
            } catch (error) {
                toast.error(error.message);
                this.outputLoading = false;
                this.outputError = true; 
                this.outputErrorMessage = error.message;
            }
        },

        validateCountriesWithOutValues(countries){
            // show countries that values == 0
            let validateCountriesWithOutValues =  countries.filter(country => {
                return country.regionCountries.some(regionCountry => {
                    return regionCountry.docProgramTopicSegItems.some(docProgramTopicSegItem => {
                        return docProgramTopicSegItem.documentProgramTopic.documentProgramValues.some(value => value.value !==0)
                    })
                })
            })
            return validateCountriesWithOutValues
        },
        cleanCountryData(){
            try {
                this.$store.dispatch('cleanRegionCountriesData');
            } catch (error) {
                console.log(error);
            }
        }
    },
    beforeUnmount() {
        this.cleanCountryData()
    },
}
</script>

<style scoped>

.export-flexColumn {
    display: flex;
    flex-direction: column;
}

.export-flexColumn-contractYear {
    display: flex;
    gap: 1rem;
}
.export-flexColumn-valueOptionDecimal{
    display: flex;
    gap: 1rem;
    width: 100%;
}

.export-flexColumn-valueOptionDecimal-container{
    width: 100%;
}

</style>