<template>
    <!-- table -->
    <div v-if="header.length > 0" style="width: 100%;">
        <table 
            style="
                width: 100%; 
                border-collapse: collapse;
            ">
            <thead>
                <tr class="topicOutputTrTextAlign">
                    <th 
                        v-for="(headerItem, index) in header" 
                        :key="index" 
                        style="
                            /* text-align: center; */
                        "
                        class="topicOutputTh"
                        >
                        {{ headerItem }}
                    </th>
                </tr>
                <tr>
                    <br />
                </tr>
            </thead>
            <tbody>
                <template 
                    v-for="(region, regionIndex) in segmentationRegionOrder"
                    :key="'region-' + regionIndex"
                >
                    <!-- HEADER -->
                    <tr class="topicOutputTrTextAlign">
                        <!-- {{ headerTotalData[regionIndex]  }} -->
                        <td 
                            v-for="(headerItem, headerItemIndex) in this.headerTotalData[regionIndex]"
                            :key="headerItemIndex"
                            
                            class="topicOutputTh"
                            >
                            <!-- INTERESTING FEATURE
                            :colspan="
                                header.length
                            "  -->
                            <!-- {{ region.name }} -->
                            <div v-if="headerItemIndex === 0"
                                style="
                                    text-align: left; 
                                    font-weight: bold;
                                "
                            >
                                {{ headerItem }} 
                            </div>
                            <div v-else 
                                style="
                                    text-align: right; 
                                    font-weight: bold;
                                "
                            >
                                {{ formatNumber(headerItem) }} 
                            </div>
                        </td>
                    </tr>
                    <!-- DATA -->
                    <tr
                        v-for="(row, rowIndex) in countryData[regionIndex]"
                        :key="'row-' + regionIndex + '-' + rowIndex"
                        class="topicOutputTrTextAlign"
                    >
                        <td 
                            v-for="(item, itemIndex) in row" 
                            :key="itemIndex" 
                            style="
                                /* text-align: center; */
                            "
                            class="topicOutputTh"
                        >
                            {{ formatNumber( item ) }}
                        </td>
                    </tr>
                    <tr>
                        <br />
                    </tr>
                </template>
            </tbody>
        </table>

        <div
        >
            <CopyTableCountryDataSimplified
                :header="this.header"
                :segmentationRegionOrder="this.segmentationRegionOrder"
                :countryData="this.countryData"
                :headerTotalData="this.headerTotalData"
            />
        </div>
    </div>
</template>

<script>
import CopyTableCountryDataSimplified from './CopyTableCountryDataSimplified.vue';


export default {
    name:'TableCountryDataSimplified',
    emits:[],
    props:['topicSegmentations','countriesData','valueDisplayOptionOutput','decimalsOptionOutput','firstYear', 'lastYear'],
    components:{
        CopyTableCountryDataSimplified

    },
    data(){
        return {
            loading:false,
            header: [],
            headerTotalData:[],
            countryData: [],

            // segmentation Region order
            segmentationRegionOrder:[],
        }
    },

    async mounted(){
        this.convertHeaderTable();
        this.orderRegions();
        this.orderCountries();
        this.createRegionTotalData();
        this.orderCountriesShare();
    },
    methods:{
        convertHeaderTable(){
            // COUNTRY, REGION, 
            // this.header = ['Country', 'Region', ]
            this.header = ['Country']

            // YEARS,
            const documentProgramValue =this.countriesData[0].regionCountries[0].docProgramTopicSegItems[0].documentProgramTopic.documentProgramValues
            // Fy
            const a = this.countriesData[0].regionCountries[0].docProgramTopicSegItems[0].documentProgramTopic.documentProgramValues[0].year
            // Ly
            const b = this.countriesData[0].regionCountries[0].docProgramTopicSegItems[0].documentProgramTopic.documentProgramValues[documentProgramValue.length -1].year

            this.header.push(a);
            this.header.push(`Share ${a}`);

            this.header.push(b);
            this.header.push(`Share ${b}`);
            
            //SUM, SHARE , CAGR FY-LY
            let fy = this.firstYear.toString().slice(-2);
            let ly = this.lastYear.toString().slice(-2);

            this.header.push(`∑${fy}-${ly}size`);
            this.header.push('Share');
            this.header.push(`CAGR ${fy}-${ly}`);

        },
        orderRegions(){
            // getSegmentationRegion and Order the data
            this.topicSegmentations.segmentationItems.forEach(element => {
                const segmRegionValues = {
                    name: element.region.name,
                    order: element.order
                }
                this.segmentationRegionOrder.push(segmRegionValues);
            });
        },
        orderCountries(){
            // prepare countryData for the order countries
            for (let index = 0; index < this.segmentationRegionOrder.length; index++) {
                this.countryData.push([])
            }
            // set countryData to the countriesData
            this.countriesData.forEach(element => {
                let countryArray = []
                
                // Name
                countryArray.push(element.name)

                // region 
                // countryArray.push(element.regionCountries[0].region.name)
                // countryArray.push('')

                // fy
                let fy = element.regionCountries[0].docProgramTopicSegItems[0].documentProgramTopic.documentProgramValues[0].value
                countryArray.push( this.toFixValueOptionDecimate(fy) )
                    // Share Fy pending
                countryArray.push( 'Share Pending' );

                // ly
                const documentProgramValuesIndex = element.regionCountries[0].docProgramTopicSegItems[0].documentProgramTopic.documentProgramValues.length
                let ly = element.regionCountries[0].docProgramTopicSegItems[0].documentProgramTopic.documentProgramValues[documentProgramValuesIndex -1].value
                countryArray.push(this.toFixValueOptionDecimate(ly))
                    // Share Ly pending
                countryArray.push( 'Share Pending' );
                
                // Sum
                let sum = this.toFixValueOptionDecimate(element.regionCountries[0].docProgramTopicSegItems[0].documentProgramTopic.sumAll) || this.toFixValueOptionDecimate(0)
                countryArray.push(sum)

                // frontendSide
                // let sum = element.regionCountries[0].docProgramTopicSegItems[0].documentProgramTopic.documentProgramValues.reduce((accumulator, current) => {
                //     return accumulator + this.toFixValueOptionDecimate(current.value)
                // })
                // countryArray.push(sum)
                
                // share
                countryArray.push('share pending')
                
                // CAGR
                let cagrValue = element.regionCountries[0].docProgramTopicSegItems[0].documentProgramTopic.cagr || 0
                countryArray.push(cagrValue.toFixed(2));

                let order =  element.regionCountries[0].region.segmentationItems[0].order
                this.countryData[order].push(countryArray)
            });
        },
        createRegionTotalData(){
            // prepare headerTotalData with the order Countries
            for (let index = 0; index < this.segmentationRegionOrder.length; index++) {
                this.headerTotalData.push([])
            }

            let countryDataArray = [...this.countryData]

            for (let index = 0; index < countryDataArray.length; index++) {
                if(countryDataArray[index].length > 0){
                    // headerTotalData  
                    // [ region1 , region2 , region3 , region4 ]
                    // [ 
                    //     [region1, region1, fyTotal1, LyTotal1, SumAllTotal1 ] ,
                    //     [region2, region2, fyTotal2, LyTotal2, SumAllTotal2 ] ,
                    //     [region3, region3, fyTotal3, LyTotal3, SumAllTotal3 ] ,
                    //     [region4, region4, fyTotal4, LyTotal4, SumAllTotal4 ] ,
                    // ]

                    // let regionName = countryDataArray[index][0][1]
                    // console.log(regionName);
                    // this.headerTotalData[index].push(regionName, regionName )
                    // this.headerTotalData[index].push(regionName,'')

                    this.headerTotalData[index].push(this.segmentationRegionOrder[index].name )


                    // Sum
                    const sumIndexFy = countryDataArray[index].reduce(
                        (accumulator, current) => {
                            return (typeof current[1] === 'string')
                            ? accumulator + parseFloat( current[1] )
                            : accumulator + current[1] ;
                            
                        }, 0);

                    const sumIndexLy = countryDataArray[index].reduce(
                        (accumulator, current) => {
                            return (typeof current[3] === 'string')
                            ? accumulator + parseFloat( current[3] )
                            : accumulator + current[3] ;
                        }, 0);

                    const sumIndexTotal = countryDataArray[index].reduce(
                        (accumulator, current) => {
                            return (typeof current[5] === 'string')
                            ? accumulator + parseFloat( current[5] )
                            : accumulator + current[5];
                        }, 0);

                    // console.log(sumIndexFy);
                    // console.log(sumIndexLy);
                    // console.log(sumIndexTotal);
                    this.headerTotalData[index].push( 
                        this.toFixValueOptionDecimate(sumIndexFy), 
                        '',
                        this.toFixValueOptionDecimate(sumIndexLy),
                        '',
                        this.toFixValueOptionDecimate(sumIndexTotal),
                        '',
                        '',
                    );
                }
                else{
                    let region = this.segmentationRegionOrder[index].name
                    this.headerTotalData[index].push( region, region,'','','','','')
                }
            }
            // console.log(this.headerTotalData);
        },
        orderCountriesShare(){
            for (let index = 0; index < this.countryData.length; index++) {
                let headerTotalDataLength = this.headerTotalData[index].length

                // ShareTotal Fy
                let fyMaxPercent = this.headerTotalData[index][1];
                // ShareTotal Ly
                let lyMaxPercent = this.headerTotalData[index][3];
                // ShareTotal
                let maxPercent = this.headerTotalData[index][headerTotalDataLength -3 ]

                this.countryData[index].forEach(element => {
                    // fy TotalShare
                    let fySumPart = element[1];
                    const fyPercentage = (fySumPart / fyMaxPercent) * 100;
                    // element[2] = fyPercentage.toFixed(1)+ '%'
                    element[2] = Math.round(fyPercentage *10)/10 + '%'

                    // ly TotalShare
                    let lySumPart = element[3];
                    const lyPercetage = (lySumPart / lyMaxPercent) * 100;
                    // element[4] = lyPercetage.toFixed(1)+ '%'
                    element[4] = Math.round(lyPercetage * 10)/10 + '%'


                    // TotalShare 
                    let sumPart = element[5] 
                    const percentage = (sumPart / maxPercent) * 100;
                    // element[6] = percentage.toFixed(1) + '%'
                    element[6] = Math.round(percentage * 10)/10  + '%'
                });
                
            }
        },

        convertValueDisplayOption(data){
            if(this.valueDisplayOptionOutput === 'billions'){
                return data / 1000;
            }
            else{
                return data
            }
        },
        toFixDecimate(data){
            if(this.decimalsOptionOutput === '0.'){
                if(Number.isInteger(data)){
                    return data
                }
                else{
                    return Math.round(data);
                }
            }
            if(this.decimalsOptionOutput === '.0'){
                return this.roundTo(data, 1).toFixed(1);
            }
            if(this.decimalsOptionOutput === '.00'){
                return this.roundTo(data, 2).toFixed(2);
            }
            if(this.decimalsOptionOutput === '.000'){
                return this.roundTo(data, 3).toFixed(3);
            }
        },
        roundTo(num, decimals){
            let factor = Math.pow(10, decimals);
            return Math.round(num * factor) / factor; 
        },
        toFixValueOptionDecimate(data){
            const valueDisplay = this.convertValueDisplayOption(data);
            return this.toFixDecimate(valueDisplay)
        },
        formatNumber(data){
            if(data){
                // split int and decimals of number
                let [int, decimal] = data.toString().split('.');

                //format int with , 
                int = int.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                return decimal ? `${int}.${decimal}` : int
            }
            else{
                return data
            }
        },
    },
    watch:{
        'countriesData':{
            async handler(newVal){
                if(newVal){
                    // cleanTable

                    // all mountedFunctions

                }

            }
        }
    }
}


</script>

<style scoped>
.topicOutputTrTextAlign>*:not(:first-child) {
    text-align: right;
}

.topicOutputTh {
    border: solid gray 1px;

    /* padding: 0 1.5% 0 1.5%; */
    padding: 0 0.3% 0 0.3%;

}

.row-zero{
    background-color:#ff7b7b ;
    color: #FFFFFF;

}
</style>